<template>
  <div class="pop_container">
    <div class="title_area">
      Comment Diary
      <a @click="closeModal" class="close">X</a>
    </div>
    <div class="body_area">
      <div class="pop_comment_div">
        <div v-if="!loading">
           <div class="comment_container" v-for="(comment,i) in comments" :key="'comment'+i">
            <i class="material-icons close_icon" @click="deleteComment(comment.courier_comment_id)">delete</i>
            <div class="comment_sub_container">
              <p>{{comment.user.name}}</p>
              <p>On {{moment(comment.created_at).format("DD-MM-YYYY HH:mm:ss")}}</p>
            </div>
            <div class="comment_sub_container">
              <p class="comment_content">{{comment.comment}}</p>
            </div>
          </div>
          <p v-if="!loading && comments.length == 0">No comments found</p>
        </div>
        <loader v-if="loading" size="60"/>
      </div>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
          <ValidationProvider name="comment" rules="required" v-slot="{ errors }" slim>
            <textarea class="write_comment_textarea" v-model="commentStatus.comment" placeholder="Type Your Comment Character Limit 100" :maxlength="max"  :class="errors[0] ? 'warning-border':''">Type your comment here</textarea>
          </ValidationProvider>
      </ValidationObserver>
      <a class="submit_comment" @click="submit">Submit</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "popUpdateStatus",
  created(){
    this.fetchComments();
  },
  data() {
    return {
      max:100,
      modalStatus: false,
      commentStatus:{
        comment:"",
        courier_id:this.id
      },
      comments:[],
      loading:true
    };
  },
  methods: {
    closeModal() {
      this.$router.go(this.$router.currentRoute);
      this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },
    fetchComments(){
      this.loading = true;
      this.axios.get("api/courier/"+ this.id +"/comments/viewall")
                .then(response => {
                  this.comments = response.data.comments;
                  this.loading = false;
                })
                .catch(error => {
                  this.loading = false;
                  console.log(error);
                });
    },
    deleteComment(id){
      if(confirm("Are you sure you want to delete this comment")){
        this.axios.delete("api/courier/comments/"+id)
                  .then(response => {
                    this.fetchComments();
                    this.toast.success(response.data.msg);
                  })
                  .catch(error => {
                    console.log(error);
                  });
      }
    },
    submit(){
        this.$refs.observer.validate();
        this.axios.post("/api/courier/comment",this.commentStatus)
        .then(response => {
          this.toast.success(response.data.msg);
          this.commentStatus.comment = "";
          this.fetchComments();
        })
        .catch(error =>{
          console.log(error);
        });
    }
  },
  props: ["id"]
};
</script>
<style lang="scss">
.pop_container {
  height: auto;
  .title_area {
    a.close {
      width: 20px;
      height: 20px;
      float: right;
      color: #fff;
      display: block;
      margin-right: 20px;
    }
  }
}
.form_section_generate_slip input[type="text"] {
  width: 100%;
}
.pop_comment_div {
  padding: 20px;
  margin: 20px 20px 0 20px;
  height:  250px;
  overflow-y:auto;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
}
.write_comment_textarea {
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding: 20px;
  margin: 20px 20px 0 20px;
  background: #fff;
  height: 100px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
}
.submit_comment {
  background: #004a7c;
  color: #fff;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  margin: 20px auto 20px 20px;
  border-radius: 10px;
}
.comment_container{
  padding:10px;
  width:100%;
  border:1px solid #ddd;
  margin-bottom:12px;
}
.comment_sub_container{
  display:flex;
  width:100;
  justify-content:space-between;
  margin-bottom:10px;
}
.comment_content{
  font-size:14px;
  width:100%;
  word-wrap: break-word;
}
.close_icon{
  cursor:pointer;
  font-size:20px !important;
  float:right;
}
</style>